import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ba48770"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero-body" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content-body" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = {
  key: 0,
  class: "title is-5"
}
const _hoisted_8 = {
  key: 1,
  class: "title is-5"
}
const _hoisted_9 = { class: "columns" }
const _hoisted_10 = { class: "column is-half" }
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "control" }
const _hoisted_14 = ["placeholder", "disabled"]
const _hoisted_15 = { class: "column" }
const _hoisted_16 = { class: "field" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "control" }
const _hoisted_19 = ["placeholder", "disabled"]
const _hoisted_20 = { class: "column" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = { class: "label" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = ["placeholder", "disabled"]
const _hoisted_25 = { class: "column" }
const _hoisted_26 = { class: "field" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "control" }
const _hoisted_29 = ["placeholder", "disabled"]
const _hoisted_30 = { class: "columns" }
const _hoisted_31 = {
  key: 0,
  class: "column is-6"
}
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "column is-2" }
const _hoisted_34 = { class: "field" }
const _hoisted_35 = { class: "label" }
const _hoisted_36 = { class: "control" }
const _hoisted_37 = ["placeholder", "disabled"]
const _hoisted_38 = { class: "column is-4" }
const _hoisted_39 = { class: "field" }
const _hoisted_40 = { class: "label" }
const _hoisted_41 = ["placeholder", "disabled"]
const _hoisted_42 = { class: "columns" }
const _hoisted_43 = { class: "column is-half" }
const _hoisted_44 = { class: "field" }
const _hoisted_45 = { class: "label" }
const _hoisted_46 = { class: "control" }
const _hoisted_47 = ["placeholder", "disabled"]
const _hoisted_48 = { class: "column is-one-quarter" }
const _hoisted_49 = { class: "field" }
const _hoisted_50 = { class: "label" }
const _hoisted_51 = { class: "control" }
const _hoisted_52 = ["placeholder", "disabled"]
const _hoisted_53 = { class: "column is-one-quarter" }
const _hoisted_54 = { class: "field" }
const _hoisted_55 = { class: "label" }
const _hoisted_56 = { class: "control" }
const _hoisted_57 = ["placeholder", "disabled"]
const _hoisted_58 = { class: "header" }
const _hoisted_59 = {
  key: 0,
  class: "title is-6 mt-4"
}
const _hoisted_60 = { class: "content-body" }
const _hoisted_61 = { class: "table-container" }
const _hoisted_62 = {
  key: 0,
  class: "table"
}
const _hoisted_63 = { class: "sticky-column" }
const _hoisted_64 = { class: "" }
const _hoisted_65 = { class: "" }
const _hoisted_66 = { class: "" }
const _hoisted_67 = { class: "" }
const _hoisted_68 = { class: "" }
const _hoisted_69 = { class: "" }
const _hoisted_70 = { class: "" }
const _hoisted_71 = { class: "content-body" }
const _hoisted_72 = {
  key: 0,
  class: "header is-flex is-justify-content-space-between"
}
const _hoisted_73 = { class: "content-body" }
const _hoisted_74 = { class: "field is-grouped is-grouped-right" }
const _hoisted_75 = {
  key: 0,
  class: "control"
}
const _hoisted_76 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_77 = {
  key: 1,
  class: "control"
}
const _hoisted_78 = {
  key: 2,
  class: "control"
}
const _hoisted_79 = {
  key: 3,
  class: "control"
}
const _hoisted_80 = { class: "content-body" }
const _hoisted_81 = { class: "header" }
const _hoisted_82 = { class: "title is-5" }
const _hoisted_83 = { class: "field is-grouped is-grouped-right" }
const _hoisted_84 = { class: "control" }
const _hoisted_85 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_86 = { class: "control" }
const _hoisted_87 = { class: "content-body" }
const _hoisted_88 = { class: "header" }
const _hoisted_89 = { class: "title is-5" }
const _hoisted_90 = { class: "field is-grouped is-grouped-right" }
const _hoisted_91 = { class: "control" }
const _hoisted_92 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_93 = { class: "control" }
const _hoisted_94 = { class: "content-body" }
const _hoisted_95 = { class: "header" }
const _hoisted_96 = { class: "title is-5" }
const _hoisted_97 = { class: "field is-grouped is-grouped-right" }
const _hoisted_98 = { class: "control" }
const _hoisted_99 = {
  type: "submit",
  class: "button is-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_CustDataPetTableRow = _resolveComponent("CustDataPetTableRow")!
  const _component_CustDataPetDataForm = _resolveComponent("CustDataPetDataForm")!
  const _component_AppointmentDataForm = _resolveComponent("AppointmentDataForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.getShowingAppointmentData)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editCustomerMeth && _ctx.editCustomerMeth(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (!_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editCustomerMeth && _ctx.editCustomerMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            (_ctx.getEditingCustomer)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('CustomerData.PleaseModifyYourCustomerData')), 1))
                              : _createCommentVNode("", true),
                            (!_ctx.getEditingCustomer)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.$t('CustomerData.YourCustomerData')), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('CustomerData.Name')), 1),
                                _createElementVNode("div", _hoisted_13, [
                                  _withDirectives(_createElementVNode("input", {
                                    minlength: 2,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getEditedCustomerData.name) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.NamePlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_14), [
                                    [_vModelText, _ctx.getEditedCustomerData.name]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('CustomerData.Email')), 1),
                                _createElementVNode("div", _hoisted_18, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getEditedCustomerData.email) = $event)),
                                    class: "input",
                                    type: "email",
                                    placeholder: _ctx.replaceSpecialCharacters(_ctx.$t('CustomerData.EmailPlaceholder')),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_19), [
                                    [_vModelText, _ctx.getEditedCustomerData.email]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('UserData.PhoneNumber')), 1),
                                _createElementVNode("div", _hoisted_23, [
                                  _withDirectives(_createElementVNode("input", {
                                    minlength: 6,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getEditedCustomerData.phone) = $event)),
                                    class: "input",
                                    type: "tel",
                                    placeholder: _ctx.$t('UserData.PhoneNumberPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_24), [
                                    [_vModelText, _ctx.getEditedCustomerData.phone]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('CustomerData.VATNumber')), 1),
                                _createElementVNode("div", _hoisted_28, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getEditedCustomerData.tax_id) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.VATNumberPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_29), [
                                    [_vModelText, _ctx.getEditedCustomerData.tax_id]
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            (_ctx.getEditingCustomer)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                  _createElementVNode("div", {
                                    class: "notification is-light is-small",
                                    innerHTML: _ctx.$t('CustomerData.PostalCodeSearchNote')
                                  }, null, 8, _hoisted_32)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_33, [
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('CustomerData.PostalCode')), 1),
                                _createElementVNode("div", _hoisted_36, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getEditedCustomerData.postal_code) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.PostalCodePlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_37), [
                                    [_vModelText, _ctx.getEditedCustomerData.postal_code]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_38, [
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('CustomerData.City')), 1),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["control", { 'is-loading': _ctx.postalCodeIsLoading }])
                                }, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getEditedCustomerData.city) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.CityPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_41), [
                                    [_vModelText, _ctx.getEditedCustomerData.city]
                                  ])
                                ], 2)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('CustomerData.Address')), 1),
                                _createElementVNode("div", _hoisted_46, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.getEditedCustomerData.address) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.AddressPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_47), [
                                    [_vModelText, _ctx.getEditedCustomerData.address]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_48, [
                              _createElementVNode("div", _hoisted_49, [
                                _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t('CustomerData.State')), 1),
                                _createElementVNode("div", _hoisted_51, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.getEditedCustomerData.state) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.StatePlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_52), [
                                    [_vModelText, _ctx.getEditedCustomerData.state]
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_53, [
                              _createElementVNode("div", _hoisted_54, [
                                _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t('CustomerData.Country')), 1),
                                _createElementVNode("div", _hoisted_56, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.getEditedCustomerData.country) = $event)),
                                    class: "input",
                                    type: "text",
                                    placeholder: _ctx.$t('CustomerData.CountryPlaceholder'),
                                    required: "",
                                    disabled: !_ctx.getEditingCustomer
                                  }, null, 8, _hoisted_57), [
                                    [_vModelText, _ctx.getEditedCustomerData.country]
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_58, [
                    (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_59, _toDisplayString(_ctx.$t('CustomerData.YourPets')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("div", _hoisted_61, [
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("table", _hoisted_62, [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", _hoisted_63, _toDisplayString(_ctx.$t('PetData.PetName')), 1),
                                _createElementVNode("th", _hoisted_64, _toDisplayString(_ctx.$t('PetData.Species')), 1),
                                _createElementVNode("th", _hoisted_65, _toDisplayString(_ctx.$t('PetData.Breed')), 1),
                                _createElementVNode("th", _hoisted_66, _toDisplayString(_ctx.$t('PetData.Gender')), 1),
                                _createElementVNode("th", _hoisted_67, _toDisplayString(_ctx.$t('PetData.Birth')), 1),
                                _createElementVNode("th", _hoisted_68, _toDisplayString(_ctx.$t('PetData.Age')), 1),
                                _createElementVNode("th", _hoisted_69, _toDisplayString(_ctx.$t('PetData.FertileStatus')), 1),
                                _createElementVNode("th", _hoisted_70, _toDisplayString(_ctx.$t('PetData.Weight')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('General.Operations')), 1)
                              ])
                            ]),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEditedCustomerPets, (pet) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: pet.pet_id
                                }, [
                                  _createVNode(_component_CustDataPetTableRow, {
                                    "pet-data": pet,
                                    language: _ctx.language
                                  }, null, 8, ["pet-data", "language"])
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_71, [
                    (!_ctx.addingPet && !_ctx.getEditedCustEditingPet
              && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                          _createElementVNode("div", null, [
                            _createElementVNode("button", {
                              class: "button is-primary",
                              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingPet && _ctx.toggleAddingPet(...args)))
                            }, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('CustomerData.NewPet')), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("div", _hoisted_74, [
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_75, [
                            _createElementVNode("button", _hoisted_76, _toDisplayString(_ctx.$t('General.Save')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && _ctx.getEditingCustomer && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_77, [
                            _createElementVNode("a", {
                              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.cancelEditing && _ctx.cancelEditing(...args))),
                              class: "button is-light"
                            }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.addingPet && !_ctx.getEditedCustEditingPet && !_ctx.getEditingCustomer && !_ctx.getShowingPetRecord && !_ctx.getCustDetailing)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_78, [
                            _createElementVNode("a", {
                              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.iniCustomerEditing && _ctx.iniCustomerEditing(...args))),
                              class: "button is-primary"
                            }, _toDisplayString(_ctx.$t('General.ModifyData')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getCustDetailing && !_ctx.getShowingPetRecord)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_79, [
                            _createElementVNode("a", {
                              onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.endCustDetailing && _ctx.endCustDetailing(...args))),
                              class: "button is-primary"
                            }, _toDisplayString(_ctx.$t('General.Back')), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_80, [
                    (_ctx.addingPet)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addPetMeth && _ctx.addPetMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_81, [
                            _createElementVNode("h5", _hoisted_82, _toDisplayString(_ctx.$t('CustomerData.AddNewPet')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refNewPetDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_83, [
                            _createElementVNode("p", _hoisted_84, [
                              _createElementVNode("button", _hoisted_85, _toDisplayString(_ctx.$t('General.Add')), 1)
                            ]),
                            _createElementVNode("p", _hoisted_86, [
                              _createElementVNode("a", {
                                onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingPet && _ctx.toggleAddingPet(...args))),
                                class: "button is-light"
                              }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_87, [
                    (_ctx.getEditedCustEditingPet)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[17] || (_cache[17] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editPetMeth && _ctx.editPetMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_88, [
                            _createElementVNode("h5", _hoisted_89, _toDisplayString(_ctx.$t('CustomerData.EditPet')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refEditedPetDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_90, [
                            _createElementVNode("p", _hoisted_91, [
                              _createElementVNode("button", _hoisted_92, _toDisplayString(_ctx.$t('General.Edit')), 1)
                            ]),
                            _createElementVNode("p", _hoisted_93, [
                              _createElementVNode("a", {
                                onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.cancelEditPet && _ctx.cancelEditPet(...args))),
                                class: "button is-light"
                              }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_94, [
                    (_ctx.getShowingPetRecord)
                      ? (_openBlock(), _createElementBlock("form", {
                          key: 0,
                          onSubmit: _cache[18] || (_cache[18] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.endShowingPetRecordMeth && _ctx.endShowingPetRecordMeth(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_95, [
                            _createElementVNode("h5", _hoisted_96, _toDisplayString(_ctx.$t('PetData.PetHistoricalRecord')), 1)
                          ]),
                          _createVNode(_component_CustDataPetDataForm, { ref: "refPetRecordDataForm" }, null, 512),
                          _createElementVNode("div", _hoisted_97, [
                            _createElementVNode("p", _hoisted_98, [
                              _createElementVNode("button", _hoisted_99, _toDisplayString(_ctx.$t('General.Back')), 1)
                            ])
                          ])
                        ], 32))
                      : _createCommentVNode("", true)
                  ])
                ], 32))
              : _createCommentVNode("", true),
            (_ctx.getShowingAppointmentData)
              ? (_openBlock(), _createBlock(_component_AppointmentDataForm, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}