<template>
  <section class="hero is-fullheight p-6">
    <div
      class="container has-background-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      <div>
        <img :src="logoPath" class="image mb-4" style="width: 70%; margin: auto;" alt="logo">
      </div>
      <h4 class="title is-4" style="text-align: center;">{{ $t('Customer.VideoAppointment') }}</h4>
      <div v-if="showLanguageSwitcher">
        <!-- Language Switcher -->
        <div class="control">
          <div class="select is-info">
            <select v-model="selectedLanguage" @change="changeLanguage">
              <option value="en">en</option>
              <option value="pt-BR">pt-BR</option>
              <option value="pt">pt</option>
            </select>
          </div>
        </div>
      </div>
      <form @submit.prevent="doLogin" class="p-3">
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input class="input" type="email" :placeholder="$t('General.EmailPlaceholder')" v-model="email" required>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="password" :placeholder="$t('General.PasswordPlaceholder')" v-model="password" required>
          </p>
        </div>
        <div class="field">
          <p class="control has-text-centered">
            <button class="button is-success is-fullwidth" :class="{ 'is-loading': isChecking }">
              {{ $t('General.Login') }}
            </button>
          </p>
        </div>
        <div class="field">
          <p class="control has-text-centered">
            <button class="button is-success is-fullwidth is-light" :class="{ 'is-loading': isChecking }">
              <a @click="registerCustomer">
                {{ $t('Customer.NewCustomer') }}
              </a>
            </button>
          </p>
        </div>
        <div class="field">
          <p class="control has-text-centered has-text-black">
          <a @click="goToResetPassword">{{ $t('General.ResetPassword') }}</a>
        </p>
        </div>
        <div class="field">
          <p class="control has-text-centered has-text-black">
          <a :href="priceTableUrl" target="_blank" @click="trackPriceTableClick">{{ $t('Customer.PriceTableLink') }}</a>
          </p>
        </div>
        <div class="field">
          <p class="control has-text-centered has-text-black">
          <a @click="openHomeWiseVetLive">{{ $t('General.AboutWiseVetLive') }}</a>
        </p>
        </div>
        <div class="field">
          <p class="control has-text-centered has-text-black">
          <a @click="openHomeWebSite">{{ $t('General.BackToWebSite') }}</a>
        </p>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Notyf } from "notyf";
import { changeLocale } from "@/main"; // Import the changeLocale function


const notyf = new Notyf()

export default defineComponent({
  name: "Login",
  data() {
    return {
      componentName: "Login",
      email: '',
      password: '',
      isChecking: false,
      selectedLanguage: process.env.APP_DEFAULT_LANGUAGE || 'en', // Default to 'en' if not set,
      logoPath: process.env.VUE_APP_LOGO_URL, // Access the Logo URL from environment variables
      envType: process.env.TYPE, // Access the type of running environment from environment variables
      homeWebSiteUrl: process.env.VUE_APP_HOMEWEBSITE_URL
    }
  },
  components: {},
  props: [],
  computed: {
    ...mapGetters(['getAccessType']),
    showLanguageSwitcher() {
      return this.envType !== 'prod';
    },
    priceTableUrl() {
      return `${process.env.FULL_BASE_URL}/terms/Price_Table_${this.$i18n.locale}.pdf`;
    },
  },
  
  methods: {
    ...mapActions(['login']),

    trackPriceTableClick() {
    this.$gtag.event('click', {
      event_category: 'Price Table',
      event_label: this.priceTableUrl,
      value: 1, // Optional: Add a numeric value for the event
    });
  },
    
    async doLogin() {
      try {

        this.isChecking = true
        const loginData = await this.login({
          email: this.email,
          password: this.password,
        })
        
        this.isChecking = false

        this.$route.query.redirect = "/" + this.getAccessType;

        if (this.$route.query.redirect != null) {
          this.$router.push(this.$route.query.redirect)
        }
        else {
          this.$router.push('/')
        }

        /* await this.$router.push('/backend') */
      } catch (e) {
        notyf.error(this.$t('General.NotyfInvalidLogin'))
        this.isChecking = false
      }
    },

    async registerCustomer() {
      this.$router.push("/registration")
    },

    async changeLanguage(event: Event) {
      const target = event.target as HTMLSelectElement;
      if (target) {
        try {
          await changeLocale(target.value);
        } catch (error) {
          console.error('Failed to change language:', error);
        }
      }
    },
    goToResetPassword()
    {
      this.$router.push('/resetpassword');
    },
    openHomeWiseVetLive()
    {
      window.open(`https://vetexpertise.com/become-a-teleconsultant/`, '_blank');
    },
    openHomeWebSite()
    {
      window.location.href = this.homeWebSiteUrl;
    }
  },
  watch: {},
  mounted() {
  }
})
</script>

<style scoped>
.logo-image {
  height: 8em;
  width: auto;
}
</style>
