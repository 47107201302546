<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="(editManagerMeth($t))">
              <div class="content-body">
                <div class="header">
                  <h5 class="title is-5" v-if="getEditingManager">
                    {{ $t('UserData.PleaseEnterYourUserData') }}
                  </h5>
                  <h5 class="title is-5" v-if="!getEditingManager">
                    {{ $t('UserData.YourUserData') }}
                  </h5>
                </div>
                <div class="columns is-vcentered">
                  <div class="column is-one-fifth mb-0 is-flex is-align-items-center is-justify-content-center">
                    <div class = "image-container">
                      <figure class="image is-128x128 pointer" @click="selectFile">
                        <img class="is-rounded" :src="userImageURL" alt="click_to_upload">
                      </figure>
                      <p v-if="getEditingManager" style="text-align: center; font-size: 12px; margin-top: 0px;">{{ $t('UserData.PleaseClickPhotoToUpdate') }}</p>
                    </div>
                  <input type="file" class="is-hidden" ref="imageInput" @change="uploadImage">
                  </div>
                  <div class="column mb-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Name') }}</label>
                      <div class="control">
                        <input :minlength='2' v-model="getEditedManagerData.name" class="input" type="text"
                        :placeholder="$t('UserData.NamePlaceholder')" required :disabled="!getEditingManager">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column mt-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Email') }}</label>
                      <div class="control">
                        <input v-model="getEditedManagerData.email" class="input" type="email"
                        :placeholder="replaceSpecialCharacters($t('UserData.EmailPlaceholder'))" required :disabled="!getEditingManager">
                      </div>
                    </div>
                  </div>
                  </div>
                <div class="field is-grouped is-grouped-right">
                  <p class="control"
                    v-if="getEditingManager">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control"
                    v-if="getEditingManager">
                    <a @click="cancelEditing" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                  <p class="control"
                    v-if="!getEditingManager">
                    <a @click="iniManagerEditing" class="button is-primary">
                      {{ $t('General.ModifyData') }}
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";

const notyf = new Notyf()

export default defineComponent({
  name: "ManagerData",
  components: { Navbar },

  data() {
    return {
      componentName: "ManagerData",
    }
  },
  computed: {
    ...mapGetters(['getEditedManagerData', 'userData', 'getEditingManager', 'getEditedInitialEmail']),
    validPassword(): boolean {
      return (this.getEditedManagerData.password === this.getEditedManagerData.confirmation && this.getEditedManagerData.password.length >= 6)
    },
    userImageURL(): string {
      const random = Math.round(99999 * Math.random());
      return process.env.BASE_URL + `api/users/image/${this.getEditedManagerData.user_id}/?hash=${random}`;
    },
  },
  methods: {
    ...mapActions(['checkEmailIsTaken', 'editManager', 'iniEditedManager', 'iniManagerEditing', 'endManagerEditing',
                   'IniInitialEmail', 'logout', 'uploadUserImage', 'toggleShowingUserData']),

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async editManagerMeth($t: any) {
      console.log("editManagerMeth")
      console.log(this.getEditedInitialEmail)
      console.log(this.getEditedManagerData.email)

      const emailChanged = (this.getEditedInitialEmail != this.getEditedManagerData.email)
      console.log(emailChanged)

      var checkEmailIsTaken: boolean = false;

      if (emailChanged)
      {
        checkEmailIsTaken = await this.checkEmailIsTaken(this.getEditedManagerData.email)
        if (!checkEmailIsTaken) {
          const confirmLogout = confirm($t && $t('UserData.ConfirmEmailChange'));
          if (confirmLogout)
          {
            try
            {
              var editedManagerId: number = await this.editManager(this.getEditedManagerData)
              console.log(editedManagerId)

              if (editedManagerId)
              {
                this.endManagerEditing()
                notyf.success(this.$t('UserData.NotyfDataEdited'))
                this.initializeEditedManager()
              }
            }
            catch (e)
            {
              notyf.error(this.$t('UserData.NotyfErrorUpdatingData'))
            }
            // Redirect the user to the login page
            this.toggleShowingUserData(true);
            this.logout()
            this.$router.push('/')
            return; // Stop further execution of the method
          }
          else
          {
            // If the user cancels, reset the email field to the initial value
            this.getEditedManagerData.email = this.getEditedInitialEmail;
            return; // Stop further execution of the method
          }
        }
        else
        {
          notyf.error(this.$t('UserData.NotyfEmailInUse'))
        }
      }
      else
      {
        // If the email didn't change the update of the data is executed and the user is not redirected to login
        try
        {
          var editedManagerId: number = await this.editManager(this.getEditedManagerData)
          console.log(editedManagerId)
          if (editedManagerId)
          {
            this.endManagerEditing()
            notyf.success(this.$t('UserData.NotyfDataEdited'))
            this.initializeEditedManager()
          }
        }
        catch (e)
        {
          notyf.error(this.$t('UserData.NotyfErrorUpdatingData'))
        }
      }
      return;
    },

    cancelEditing() {
      this.endManagerEditing()
      this.clearFormManager()
    },

    async clearFormManager() {
      // This function seems not to be doing anything from where it is called
      // redundant ?
      const resultIniManager = await this.iniEditedManager(this.userData.email);
      this.IniInitialEmail(resultIniManager.email)
    },

    async initializeEditedManager() {
      console.log("initializeEditedManager")
      console.log(this.userData.email);
      const resultIniManager = await this.iniEditedManager(this.userData.email);
      this.IniInitialEmail(resultIniManager.email)
      console.log(resultIniManager);
      console.log(this.getEditedManagerData);
      console.log(this.getEditedManagerData.user_id);

    },

    selectFile()
    {
      // Ensure that this.$refs.imageInput is not undefined before calling click()
      const imageInputRef = this.$refs.imageInput as HTMLInputElement;
      if (imageInputRef) {
        imageInputRef.click();
      } else {
        console.error("Image input ref is not defined.");
      }
    },
    async uploadImage()
    {
      console.log("uploadImage method is called")
      const imageInputRef = this.$refs.imageInput as HTMLInputElement;
      const file = imageInputRef?.files?.[0];
      
      if (!file) {
        console.error("No file selected.");
        return;
      }
      try {
        this.uploadUserImage({ user_id: this.getEditedManagerData.user_id, file: file});
        this.initializeEditedManager();
      } catch (e) {
        notyf.error(this.$t('UserData.NotyfErrorUploadingPhoto'));
      }
    }
  },
  watch: {},
  mounted()
  {
    this.toggleShowingUserData(false);
   },
  beforeMount() {
    this.initializeEditedManager();
  }

})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
</style>